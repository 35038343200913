import React from "react";
import { ProductSubCategoryHeroProps } from "./Hero";
import { useTheme } from "@ryerson/frontend.theme";
import { ContentSection, Flex, FlexItem } from "@ryerson/frontend.layout";
import { Grid, Row, Column } from "@ryerson/frontend.layout";
import { Typography } from "@ryerson/frontend.typography";
import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { Button } from "@ryerson/frontend.button";
import { Icon } from "@ryerson/frontend.assets";
import { Link, Breadcrumb } from "@ryerson/frontend.navigation";
import { ShopByShapeItem, ContentfulGeneralReference } from "../Types/Types";

const HeaderDiv = styled.div`
	margin-top: 25px;
	margin-bottom: 55px;
`;

const ReferencesDiv = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.darkerGray};
			padding: 13px 45px 7px;
			box-sizing: border-box;
			min-height: 86%;
		`;
	}}
`;

const TableDiv = styled.div`
	${(props: any) => {
		const { theme } = props;
		return css`
			background-color: ${theme.colors.primary.background};
		`;
	}}
	margin-bottom: 61px;
`;

const BreadcrumbContainer = styled.div`
	margin-top: 32px;
`;

const ProductsSubCategoryHeroDesktop: React.FC<ProductSubCategoryHeroProps> = ({
	staticContent,
	dynamicContent,
}) => {
	const { theme } = useTheme();

	const tableCell = css`
		margin-left: 26px;
	`;

	const ProductsTable = () => {
		if (dynamicContent.shopByShape && dynamicContent.shopByShape.length > 0) {
			return (
				<Grid>
					<Row
						style={css`
							background-color: ${theme.colors.primary.lighterGray};
							margin: 0px 5px;
						`}
					>
						<Column lg={3}>
							<Typography
								type="primary"
								variant="p"
								size="md"
								color={theme.colors.primary.label}
								weight="bold"
								css={tableCell}
							>
								{staticContent.shopByShapeHeader.shape}
							</Typography>
						</Column>
						<Column lg={3}>
							<Typography
								type="primary"
								variant="p"
								size="md"
								color={theme.colors.primary.label}
								weight="bold"
								css={tableCell}
							>
								{staticContent.shopByShapeHeader.grade}
							</Typography>
						</Column>
						<Column lg={3}>
							<Typography
								type="primary"
								variant="p"
								size="md"
								color={theme.colors.primary.label}
								weight="bold"
								css={tableCell}
							>
								{staticContent.shopByShapeHeader.type}
							</Typography>
						</Column>
						<Column lg={3}>
							<Typography
								type="primary"
								variant="p"
								size="md"
								color={theme.colors.primary.label}
								weight="bold"
								css={tableCell}
							>
								{staticContent.shopByShapeHeader.size}
							</Typography>
						</Column>
					</Row>
					{dynamicContent.shopByShape.map(function (
						product: ShopByShapeItem,
						index: number
					) {
						return (
							<Row
								style={css`
									:after {
										content: "";
										display: block;
										width: 95%;
										margin: 10px auto;
										margin-bottom: 5px;
										border-bottom: 1px solid ${theme.colors.primary.header};
										opacity: 0.1;
									}
									:last-of-type::after {
										content: "";
										border-bottom: 0px;
										margin: 0px;
									}
								`}
							>
								<Column lg={3}>
									<Link key={index} to={product.link ?? "#"} gatsby={false}>
										<Flex css={tableCell}>
											<FlexItem>
												<img
													css={css`
														width: 60px;
														height: 60px;
													`}
													src={product.rendering?.file.url}
												/>
											</FlexItem>
											<FlexItem
												alignSelf="center"
												css={css`
													padding-left: 20px;
												`}
											>
												<Typography
													variant="p"
													color={theme.colors.primary.link}
													css={css`
														margin-top: 4px;
													`}
												>
													{product.shape}
												</Typography>
											</FlexItem>
										</Flex>
									</Link>
								</Column>
								<Column lg={3}>
									<Typography
										type="primary"
										variant="p"
										size="md"
										color={theme.colors.primary.label}
										css={tableCell}
									>
										{product.grade}
									</Typography>
								</Column>
								<Column lg={3}>
									<Typography
										type="primary"
										variant="p"
										size="md"
										color={theme.colors.primary.label}
										css={tableCell}
									>
										{product.type}
									</Typography>
								</Column>
								<Column lg={3}>
									{product.size &&
									Array.isArray(product.size) &&
									product.size.length > 0 ? (
										product.size.map(function (text: string, index: number) {
											return (
												<Typography
													type="primary"
													variant="p"
													size="md"
													color={theme.colors.primary.label}
													css={css`
														margin-left: 26px;
														margin-bottom: 0px;
														margin-top: 0px;
														:first-of-type {
															margin-top: 16px;
														}
													`}
													key={index}
												>
													{text}
												</Typography>
											);
										})
									) : (
										<Typography
											type="primary"
											variant="p"
											size="md"
											color={theme.colors.primary.label}
											css={css`
												margin-left: 26px;
												margin-bottom: 0px;
												margin-top: 0px;
												:first-of-type {
													margin-top: 16px;
												}
											`}
											key={index}
										>
											{product.size}
										</Typography>
									)}
								</Column>
							</Row>
						);
					})}
				</Grid>
			);
		} else {
			return <></>;
		}
	};

	return (
		<>
			<ContentSection type="tertiary">
				<BreadcrumbContainer>
					<Breadcrumb type="tertiary" size="xs" />
				</BreadcrumbContainer>
				<HeaderDiv>
					<Flex alignContent="space-between" justifyContent="space-between">
						<FlexItem>
							<Typography
								type="tertiary"
								variant="h1"
								css={css`
									font-size: 75px;
									line-height: 75px;
								`}
							>
								{dynamicContent.title}
							</Typography>
						</FlexItem>
						<Link to={staticContent.buttonUrl} gatsby={false}>
							<FlexItem>
								<Button
									size="lg"
									type="secondary"
									label={staticContent.buttonLabel}
									onClick={() => {}}
								></Button>
							</FlexItem>
						</Link>
					</Flex>
				</HeaderDiv>
				<Flex
					alignContent="space-between"
					css={css`
						padding-bottom: 54px;
					`}
					justifyContent="space-between"
				>
					<FlexItem
						grow={1}
						css={css`
							width: 365px;
							max-width: 365px;
						`}
					>
						<Flex
							css={css`
								height: 100%;
							`}
							justifyContent="space-between"
							direction="column"
						>
							<FlexItem>
								<Typography
									type="tertiary"
									variant="p"
									size="md"
									css={css`
										margin-bottom: 20px;
									`}
								>
									{dynamicContent.blurb}
								</Typography>
								{dynamicContent.selectionGuide &&
								dynamicContent.selectionGuide.file ? (
									<>
										<Link
											to={dynamicContent.selectionGuide.file.url}
											gatsby={false}
										>
											<Typography
												type="tertiary"
												variant="p"
												size="md"
												css={css`
													margin-top: 40px;
													margin-bottom: 20px;
												`}
												color={theme.colors.primary.white}
											>
												<Icon
													icon="file-pdf"
													color={theme.colors.primary.white}
													css={css`
														vertical-align: middle;
														margin-right: 11px;
													`}
												/>
												{staticContent.selectionGuide +
													": " +
													dynamicContent.title}
											</Typography>
										</Link>
									</>
								) : (
									<></>
								)}
								{dynamicContent.shopNowLink && (
									<>
										<Link to={dynamicContent.shopNowLink} gatsby={false}>
											<Button
												type="primary"
												size="lg"
												label={staticContent.shopNow}
												onClick={() => {}}
											/>
										</Link>
									</>
								)}
							</FlexItem>
						</Flex>
					</FlexItem>
					<FlexItem
						grow={1}
						css={css`
							width: 360px;
							max-width: 360px;
						`}
					>
						<Typography
							variant="h3"
							type="tertiary"
							css={css`
								margin-bottom: 30px;
							`}
						>
							{staticContent.generalReference}
						</Typography>
						<ReferencesDiv theme={theme}>
							{dynamicContent.references.map(
								(ref: ContentfulGeneralReference, index: number) => {
									return (
										<Link key={index} to={ref.pdf.file.url} gatsby={false}>
											<Typography
												variant="p"
												type="tertiary"
												size="md"
												color={theme.colors.primary.white}
												css={css`
													margin-bottom: 30px;
												`}
											>
												<Icon
													icon="file-pdf"
													color={theme.colors.primary.white}
													css={css`
														vertical-align: middle;
														margin-right: 11px;
													`}
												/>
												{ref.pdf.title}
											</Typography>
										</Link>
									);
								}
							)}
						</ReferencesDiv>
					</FlexItem>
					{dynamicContent.properties && dynamicContent.properties.length > 0 ? (
						<>
							<FlexItem
								grow={1}
								css={css`
									width: 360px;
									max-width: 360px;
								`}
							>
								<Typography
									variant="h3"
									type="tertiary"
									css={css`
										margin-bottom: 30px;
									`}
								>
									{staticContent.materialProperties}
								</Typography>
								<ReferencesDiv theme={theme}>
									{dynamicContent.properties.map(
										(ref: ContentfulGeneralReference, index: number) => {
											return (
												<Link
													key={index}
													to={ref.pdf.file.url}
													gatsby={false}
												>
													<Typography
														variant="p"
														type="tertiary"
														size="md"
														color={theme.colors.primary.white}
														css={css`
															margin-bottom: 30px;
														`}
													>
														<Icon
															icon="file-pdf"
															color={theme.colors.primary.white}
															css={css`
																vertical-align: middle;
																margin-right: 11px;
															`}
														/>
														{ref.pdf.title}
													</Typography>
												</Link>
											);
										}
									)}
								</ReferencesDiv>
							</FlexItem>
						</>
					) : (
						<></>
					)}
				</Flex>
				{dynamicContent.shopByShape && dynamicContent.shopByShape.length > 0 && (
					<Typography
						variant="h1"
						type="tertiary"
						css={css`
							margin-top: 0px;
							margin-bottom: 60px;
						`}
					>
						{staticContent.shopByShape}
					</Typography>
				)}
				<TableDiv theme={theme}>
					<ProductsTable />
				</TableDiv>
			</ContentSection>
		</>
	);
};

export default ProductsSubCategoryHeroDesktop;
